import { Source } from '../ui-components/responsive-kontext-info-header/responsive-kontext-info-header.component';

// Auflösungen der Bilder
export const resolutions = [
  { width: 575, height: 240 },
  { width: 767, height: 240 },
  { width: 991, height: 415 },
  { width: 1920, height: 330 }
];

/**
 *Erstelle die webp /JPG Ressourcen für die Bilder, die an dem angegebenen Pfad liegen.
 * @param path
 */
export const createImageSources = (path: string): Source[] => {
  const headerSourcesJpg: Source[] = resolutions.map((resolution) => ({
    src: `${path}_${resolution.width}x${resolution.height}.jpg`,
    media: resolution.width === 1920 ? null : `(max-width: ${resolution.width}px)`,
    type: 'image/jpeg'
  }));
  const headerSourcesWebp: Source[] = resolutions.map((resolution) => ({
    src: `${path}_${resolution.width}x${resolution.height}.webp`,
    media: resolution.width === 1920 ? null : `(max-width: ${resolution.width}px)`,
    type: 'image/webp'
  }));
  return [...headerSourcesWebp, ...headerSourcesJpg];
};
