<header
  id="kontextinfo-header"
  class="ba-content-header ba-with-overlay ba-full-width ba-with-image"
  [ngClass]="{
    'ba-with-addon': withAddOn
  }"
>
  <div class="ba-header-image d-print-none">
    <picture class="ba-responsive-picture">
      <source
        *ngFor="let source of sources"
        [attr.media]="source.media"
        [attr.srcset]="source.src"
        [attr.type]="source.type"
      />
      <img id="kontextinfo-header-image" [src]="sources[0].src" alt="" />
    </picture>
  </div>

  <div class="ba-content-wrap">
    <nav
      id="kontextinfo-header-breadcrumb"
      *ngIf="breadcrumbs"
      class="ba-breadcrumb"
      aria-label="Du befindest Dich hier:"
    >
      <ol>
        <li *ngFor="let breadcrumb of breadcrumbs.parents">
          <a [routerLink]="breadcrumb.path" [queryParams]="breadcrumb.queryParams">
            {{ breadcrumb.name }}</a
          >
        </li>
        <li *ngIf="breadcrumbs.current">
          {{ breadcrumbs.current }}
        </li>
      </ol>
    </nav>

    <div class="ba-overlay">
      <h1 id="ueberschrift" [innerHtml]="headline"></h1>
      <p id="kontextinfo-header-subheadline" *ngIf="intro" [innerHtml]="intro"></p>
    </div>
  </div>
</header>
