import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Params, RouterLink } from '@angular/router';

export interface Breadcrumb {
  name: string;
  path: string;
  queryParams?: Params;
}

export type ImageTypes = 'apng' | 'avif' | 'gif' | 'jpeg' | 'png' | 'svg+xml' | 'webp';

export interface Source {
  media: `(${'max' | 'min'}-width: ${number}px)` | null;
  src: string;
  type: `image/${ImageTypes}`;
}

export type Breadcrumbs = { parents: Breadcrumb[]; current?: string };

@Component({
  selector: 'ba-studisu-responsive-kontext-info-header',
  templateUrl: './responsive-kontext-info-header.component.html',
  styleUrls: ['./responsive-kontext-info-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgClass, NgFor, NgIf, RouterLink]
})
export class ResponsiveKontextInfoHeaderComponent {
  @Input()
  breadcrumbs?: Breadcrumbs;

  @Input()
  headline: string;

  @Input()
  intro?: string;

  @Input()
  sources: Source[];

  @Input()
  withAddOn: boolean = false;
}
